import ListGroupItemText from "reactstrap/lib/ListGroupItemText";
import {
  GET_TEMPLATES_MESSAGE,
  UPDATE_ONE_TEMPLATES_MESSAGE,
  CREATE_TEMPLATES_MESSAGE,
  SET_TEMPLATES_MESSAGE,
  ADD_TEMPLATES_MESSAGE,
  RESET_STATUS_QUERY_TEMPLATES_MESSAGE,
  UPDATE_STATUS_QUERY_TEMPLATES_MESSAGE,
  SET_TEMPLATES_MESSAGE_SORTED,
  SET_TEMPLATES_INTERACTIVE_SORTED,
} from "./constants";

const initialState = {
  templatesMessage: [],
  templatesMessageSorted: [],
  templatesInteractiveSorted: [],
  loadingCreate: false,
  errorCreate: false,
  loadingUpdate: false,
  errorUpdate: false,
  loadingDelete: false,
  errorDelete: false,
};

const Templates = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ONE_TEMPLATES_MESSAGE:
      let copyTemplates = state.templatesMessage;

      let newTemplates = [action.payload];
      let updateTemplates = copyTemplates.map((template) => {
        let templateMessage = newTemplates.find(({ id }) => id === template.id);
        return templateMessage ? templateMessage : template;
      });
      return {
        ...state,
        templatesMessage: updateTemplates,
      };
    case SET_TEMPLATES_MESSAGE:
      return { ...state, templatesMessage: action.payload };

    case SET_TEMPLATES_MESSAGE_SORTED:
      return { ...state, templatesMessageSorted: action.payload };

    case SET_TEMPLATES_INTERACTIVE_SORTED:
      return { ...state, templatesInteractiveSorted: action.payload };

    case ADD_TEMPLATES_MESSAGE:
      return {
        ...state,
        templatesMessage: [...state.templatesMessage, action.payload],
      };
    case RESET_STATUS_QUERY_TEMPLATES_MESSAGE:
      return {
        ...state,
        loadingCreate: false,
        errorCreate: false,
        loadingUpdate: false,
        errorUpdate: false,
        loadingDelete: false,
        errorDelete: false,
      };

    case UPDATE_STATUS_QUERY_TEMPLATES_MESSAGE:
      return {
        ...state,
        [action.payload.type]: action.payload.status,
      };
    default:
      return state;
  }
};

export default Templates;
