import {
  SET_TEMPLATES,
  SET_ACTIVE_SIDEBAR_TAB,
  SET_SORTED_TEMPLATES,
  CHANGE_VIEW_TEMPLATES,
  CHANGE_USER_IN_TEMPLATES,
  SET_WA_TEMPLATES,
  CHANGE_EDIT_WA_TEMPLATE_ID,
} from "./constants";

const initialState = {
  templates: [],
  activeSidebarTab: "",
  sortedTemplates: [],
  view: "registered",
  userInTemplates: false,
  waTemplates: [],
  editWaTemplateId: null,
  editWaTemplateButtons: [],
};

const Templates = (state = initialState, action) => {
  switch (action.type) {
    case SET_TEMPLATES:
      return { ...state, templates: action.payload };
    case SET_SORTED_TEMPLATES:
      return { ...state, sortedTemplates: action.payload };
    case CHANGE_USER_IN_TEMPLATES:
      return { ...state, userInTemplates: action.payload }
    case CHANGE_VIEW_TEMPLATES:
      return { ...state, view: action.payload }
    case SET_WA_TEMPLATES:
      return { ...state, waTemplates: action.payload };
    case SET_ACTIVE_SIDEBAR_TAB:
      return { ...state, activeSidebarTab: action.payload };
    case CHANGE_EDIT_WA_TEMPLATE_ID:
      return {
        ...state,
        ...(action.payload.id ? {
          editWaTemplateId: action.payload.id,
          ...(action.payload.buttons && { editWaTemplateButtons: action.payload.buttons }),
        } : {
          editWaTemplateId: null,
          editWaTemplateButtons: [],
        }),
      };
    default:
      return state;
  }
};

export default Templates;
