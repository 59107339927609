import React from 'react'
import { motion } from "framer-motion";

import "./style.scss";

export default function index() {
    return (
        <div className="arya-loading">
            Loading ...
        </div>
    )
}
