export const GET_TEMPLATES_MESSAGE = "GET_TEMPLATES_MESSAGE";
export const GET_TEMPLATES_MESSAGE_ERROR = "GET_TEMPLATES_MESSAGE_ERROR";
export const GET_TEMPLATES_MESSAGE_SUCCESS = "GET_TEMPLATES_MESSAGE_SUCCESS";
export const SET_TEMPLATES_MESSAGE = "SET_TEMPLATES_MESSAGE";

export const CREATE_TEMPLATES_MESSAGE = "CREATE_TEMPLATES_MESSAGE";
export const UPDATE_STATUS_QUERY_TEMPLATES_MESSAGE =
  "UPDATE_STATUS_QUERY_TEMPLATES_MESSAGE";
export const RESET_STATUS_QUERY_TEMPLATES_MESSAGE =
  "RESET_STATUS_QUERY_TEMPLATES_MESSAGE";

export const UPDATE_TEMPLATES_MESSAGE = "UPDATE_TEMPLATES_MESSAGE";
export const UPDATE_ONE_TEMPLATES_MESSAGE = "UPDATE_ONE_TEMPLATES_MESSAGE";
export const ADD_TEMPLATES_MESSAGE = "ADD_TEMPLATES_MESSAGE";

export const SET_TEMPLATES_MESSAGE_SORTED = "SET_TEMPLATES_MESSAGE_SORTED";
export const SET_TEMPLATES_INTERACTIVE_SORTED = "SET_TEMPLATES_INTERACTIVE_SORTED";
