import {
  LOCK_LOADING_GENERAL_APP,
  SET_EVENT_NAME_SHOW_BLUR,
  SET_ROWS_AREA,
  SET_ERROR_CODES,
} from "./constants";

const initialState = {
  loading: false,
  eventNameShowBlur: '',
  nroRowsArea: 1,
  errorCodes: [],
};

const General = (state = initialState, action) => {
  switch (action.type) {
    case LOCK_LOADING_GENERAL_APP:
      return { ...state, loading: action.payload };

    case SET_EVENT_NAME_SHOW_BLUR:
      return {
        ...state,
        eventNameShowBlur: action.payload
      };
    case SET_ROWS_AREA:
      return {
        ...state,
        nroRowsArea: action.payload
      };
    case SET_ERROR_CODES:
      return {
        ...state,
        errorCodes: action.payload
      };

    default:
      return state;
  }
};

export default General;
