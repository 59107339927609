import { APIClient } from "../../helpers/apiClient";

import {
  GET_TAGS,
  GET_TAGS_SUCCESS,
  GET_TAGS_LOADING,
  GET_TAGS_ERROR,
  CREATE_TAG,
  CREATE_TAG_SUCCESS,
  CREATE_TAG_LOADING,
  CREATE_TAG_ERROR,
  UPDATE_TAG,
  DELETE_TAG,
  DUPLICATE_CREATE_TAG_ERROR
} from "./constants";

import {
  setPermissionsCurrentGroup,
} from "../actions";

const apiClient = new APIClient();
const { get, create, put } = apiClient;

function removeItemAll(arr, value) {
  var i = 0;
  while (i < arr.length) {
    if (arr[i] === value) {
      arr.splice(i, 1);
    } else {
      ++i;
    }
  }
  return arr;
}

export const duplicateCreateTagError = (message, status, data) => {
  return {
    type: DUPLICATE_CREATE_TAG_ERROR,
    payload: { message, status, data },
  };
};

export const getTags = () => async (dispatch, getState) => {
  try {
    const currentGroup = getState().Group.currentGroup;
    const permissionsCurrentGroup = getState().Auth.permissionsCurrentGroup;
    const membersOfGroup = getState().Members.members;

    if (currentGroup) {
      const { id: groupId } = currentGroup;

      if (
        permissionsCurrentGroup.role === "ROLE_PUBLISHER"
      ) {
        const response = await get("/v2/tags", { params: { groupId } });
        const id_after = getState().Group.currentGroup.id;
        if (groupId === id_after) {
          let filterFormatTags = [];
          let tagIdsList = [...response];

          if (membersOfGroup) {
            membersOfGroup.forEach((member) => {
              if (
                member?.role === "ROLE_PUBLISHER" &&
                member.tagIdList?.length > 0
              ) {
                member.tagIdList.forEach((item) => {
                  tagIdsList = tagIdsList.filter((tag) => tag.id !== item);
                });
              }
            });
          }

          if (
            permissionsCurrentGroup.tagIdList &&
            permissionsCurrentGroup.tagIdList.length > 0
          ) {
            permissionsCurrentGroup.tagIdList.forEach((tag) => {
              tagIdsList = tagIdsList.concat({ id: tag });
            });
          }

          tagIdsList.forEach((iTag) => {
            let valuesTagId = null;
            response.forEach((iResponseTag) => {
              if (iResponseTag.id === iTag.id) {
                valuesTagId = {
                  _id: iResponseTag._id,
                  groupId: iResponseTag.groupId,
                  id: iResponseTag.id,
                  name: iResponseTag.name,
                  ...(iResponseTag.archived && { archived: true }),
                };
              }
            });
            if (valuesTagId) filterFormatTags.push(valuesTagId);
          });
          dispatch(getTagsSuccess(filterFormatTags));
        }
      } else {
        const response = await get("/v2/tags", { params: { groupId } });
        const id_after = getState().Group.currentGroup.id;
        if (groupId === id_after) dispatch(getTagsSuccess(response));
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const getTagsSuccess = (tags) => {
  return {
    type: GET_TAGS_SUCCESS,
    payload: tags,
  };
};

export const getTagsLoading = (loadingStatus) => {
  return {
    type: GET_TAGS_LOADING,
    payload: loadingStatus,
  };
};

export const getTagsError = (message, status) => {
  return {
    type: GET_TAGS_ERROR,
    payload: { message, status },
  };
};

export const createTag = (tag) => async (dispatch, getState) => {
  try {
    const currentGroup = getState().Group.currentGroup;
    const dataTags = getState().Tags.data;
    const permissionsCurrentGroup = getState().Auth.permissionsCurrentGroup;

    if (currentGroup) {
      const { id: groupId } = currentGroup;

      let bodyTag = {};
      bodyTag.name = tag.label;
      bodyTag.groupId = groupId;

      const arrayTags = await get("/v2/tags", { params: { groupId } });

      const id_after = getState().Group.currentGroup.id;
      if (groupId === id_after) {
        let isFound = arrayTags.filter(
          (iTag) => iTag.name.toLowerCase() === tag.label.toLowerCase()
        );

        if (isFound.length === 0) {
          const response = await create("/v2/tags", bodyTag);

          const id_after2 = getState().Group.currentGroup.id;

          if (id_after2 === id_after) {
            let copyDataTags = [...dataTags];
            copyDataTags.push(response);

            let copyPermissionsCurrentGroup = permissionsCurrentGroup;
            let updateTagIdList = [];

            if (
              copyPermissionsCurrentGroup.tagIdList &&
              copyPermissionsCurrentGroup.tagIdList.length > 0
            ) {
              updateTagIdList = [
                ...new Set([...copyPermissionsCurrentGroup.tagIdList, response.id]),
              ];
              copyPermissionsCurrentGroup.tagIdList = updateTagIdList;
            } else {
              let newFormatTagIdList = [response.id];
              copyPermissionsCurrentGroup.tagIdList = newFormatTagIdList;
            }

            dispatch(setPermissionsCurrentGroup(copyPermissionsCurrentGroup));

            dispatch(createTagSuccess(copyDataTags));
          }
        } else {
          dispatch(
            duplicateCreateTagError(
              `El último tag {${tag.payload.label}} ingresado, ya está tomado por otro usuario.`,
              true,
              tag.payload.label
            )
          );
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const createTagSuccess = (tags) => {
  return {
    type: CREATE_TAG_SUCCESS,
    payload: tags,
  };
};

export const createTagLoading = (loadingStatus) => {
  return {
    type: CREATE_TAG_LOADING,
    payload: loadingStatus,
  };
};

export const createTagError = (message, status) => {
  return {
    type: CREATE_TAG_ERROR,
    payload: { message, status },
  };
};

export const updateTag = (idTag, tag) => {
  return {
    type: UPDATE_TAG,
    payload: { idTag, tag },
  };
};

export const deleteTag = (idTag) => {
  return {
    type: DELETE_TAG,
    payload: idTag,
  };
};

export const archiveTag = (tagId) => async (dispatch, getState) => {
  try {
    const tagsData = getState().Tags.data;
    const id_before = getState().Group.currentGroup.id;
    await put(`/v2/tags/${tagId}/archived`);
    const id_after = getState().Group.currentGroup.id;
    if (id_before === id_after) {
      const newTagsData = tagsData.map(tag => {
        if (tag._id === tagId) {
          return {
            ...tag,
            archived: true,
          };
        }
        return tag;
      });
      dispatch(getTagsSuccess(newTagsData));
    }
  } catch (error) {
    console.log(error);
  }
};
