export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGOUT_USER = "LOGOUT_USER";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const API_FAILED = "AUTH_API_FAILED";
export const SET_GROUPS = "SET_GROUPS";
export const SET_PERMISSIONS_CURRENT_GROUP = "SET_PERMISSIONS_CURRENT_GROUP";
export const SET_ENTER_KEY_SWITCH_CHECKED = "SET_ENTER_KEY_SWITCH_CHECKED";
export const SET_SERVICE_STATUS = "SET_SERVICE_STATUS";
