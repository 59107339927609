// @flow
import {
  SET_ACTIVE_TAB,
  OPEN_USER_PROFILE_SIDEBAR,
  CLOSE_USER_PROFILE_SIDEBAR,
  SET_CONVERSATION_NAME_IN_OPEN_CHAT,
  OPEN_TEMPLATES_SIDEBAR,
  CLOSE_TEMPLATES_SIDEBAR,
  OPEN_SPERANT_EXTENSION_SIDEBAR,
  CLOSE_SPERANT_EXTENSION_SIDEBAR,
} from "./constants";

const INIT_STATE = {
  activeTab: "",
  userSidebar: false,
  conversationName: "Doris Brown",
  templatesSidebar: false,
  showSperantExtension: false,
};

const Layout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };

    case OPEN_USER_PROFILE_SIDEBAR:
      return {
        ...state,
        userSidebar: true,
      };

    case CLOSE_USER_PROFILE_SIDEBAR:
      return {
        ...state,
        userSidebar: false,
      };

    case SET_CONVERSATION_NAME_IN_OPEN_CHAT:
      return {
        ...state,
        conversationName: action.payload,
      };
    case OPEN_TEMPLATES_SIDEBAR:
      return {
        ...state,
        templatesSidebar: true,
      };
    case CLOSE_TEMPLATES_SIDEBAR:
      return {
        ...state,
        templatesSidebar: false,
      };
    case OPEN_SPERANT_EXTENSION_SIDEBAR:
      return {
        ...state,
        showSperantExtension: true,
      };
    case CLOSE_SPERANT_EXTENSION_SIDEBAR:
      return {
        ...state,
        showSperantExtension: false,
      };
    default:
      return state;
  }
};

export default Layout;
