// @flow
import {
  SET_CAMPAIGNS,
} from "./constants";

const INIT_STATE = {
  campaigns: [],
};

const Campaigns = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CAMPAIGNS:
      return {
        ...state,
        campaigns: action.payload
      };

    default:
      return state;
  }
};

export default Campaigns;
