import {
  UPDATE_DATA_CURRENT_GROUP,
  CREATE_AUTORESPONSE_GROUP_REQUEST,
  CREATE_AUTORESPONSE_GROUP_REQUEST_SUCCESS,
  UPDATE_SCHEDULE_AUTORESPONSE_GROUP,
  GET_GROUP,
  SET_GROUPS,
  UPDATE_CHAT_STATUS_LABEL,
  SET_CHAT_STATUS_TAGS,
  SET_MESSAGE_LIMIT,
  TOGGLE_TEMPLATES_MANAGED_BY_ADMIN,
  TOGGLE_FREE_CONTACTS_NOT_VISIBLE_BY_PUBLISHER,
  TOGGLE_RESTRICTION_FOR_PUBLISHER,
  TOGGLE_TEAM_RESTRICTION_FOR_USER_OUT_TEAM,
  SET_TAGS_LVL,
  SET_LEVELS,
} from "./constants";

const initialState = {
  currentGroup: {},
  groups: null,
  tagsLvl: [],
  levels: [],
};

const Contact = (state = initialState, action) => {
  switch (action.type) {
    case SET_LEVELS:
      return {
        ...state,
        levels: action.payload,
      };

    case SET_TAGS_LVL:
      return {
        ...state,
        tagsLvl: action.payload,
      };

    case UPDATE_DATA_CURRENT_GROUP:
      return {
        ...state,
        currentGroup: action.payload,
      };

    case CREATE_AUTORESPONSE_GROUP_REQUEST_SUCCESS:
      return {
        ...state,
        currentGroup: {
          ...state.currentGroup,
          autoResponse: {
            media: action.payload.media,
            text: action.payload.text,
          },
        },
      };
    case SET_GROUPS:
      return {
        ...state,
        groups: action.payload,
      };

    case UPDATE_CHAT_STATUS_LABEL:
      return {
        ...state,
        currentGroup: {
          ...state.currentGroup,
          chatStatusSetByUserLabel: action.payload.chatStatusSetByUserLabel,
        },
      };

    case UPDATE_SCHEDULE_AUTORESPONSE_GROUP:
      return {
        ...state,
        currentGroup: {
          ...state.currentGroup,
          scheduleAutoResponse: {
            scheduleEnabled: action.payload.scheduleEnabled,
            text: action.payload.text,
            startAt: action.payload.startAt,
            endAt: action.payload.endAt,
          },
        },
      };

    case SET_CHAT_STATUS_TAGS:
      return {
        ...state,
        currentGroup: {
          ...state.currentGroup,
          chatStatusTags: action.payload,
        },
      };

    case SET_MESSAGE_LIMIT:
      return {
        ...state,
        currentGroup: {
          ...state.currentGroup,
          messageLimit: action.payload,
        },
      };

    case TOGGLE_TEMPLATES_MANAGED_BY_ADMIN:
      return {
        ...state,
        currentGroup: {
          ...state.currentGroup,
          templatesManagedByAdmin: !!action.payload,
        },
      };

    case TOGGLE_FREE_CONTACTS_NOT_VISIBLE_BY_PUBLISHER:
      return {
        ...state,
        currentGroup: {
          ...state.currentGroup,
          freeContactsNotVisibleByPublisher: !!action.payload,
        },
      };

    case TOGGLE_RESTRICTION_FOR_PUBLISHER:
      const { restrictionField, isEnabled } = action.payload;
      return {
        ...state,
        currentGroup: {
          ...state.currentGroup,
          [restrictionField]: !!isEnabled,
        },
      };

      case TOGGLE_TEAM_RESTRICTION_FOR_USER_OUT_TEAM:
        return {
          ...state,
          currentGroup: {
            ...state.currentGroup,
            teamRestrictionForUserOutTeam: !!action.payload,
          },
        };
  
    default:
      return { ...state };
  }
};

export default Contact;
