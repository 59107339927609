import axios from "axios";
import config from "./../config";

// default
axios.defaults.baseURL = config.API_URL;
// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

const msgAxiosInstance = axios.create({
  baseURL: config.MESSAGE_API_URL,
});
msgAxiosInstance.defaults.headers.post["Content-Type"] = "application/json";

const sperantAxiosInstance = axios.create({
  baseURL: config.SPERANT_API_URL,
});
sperantAxiosInstance.defaults.headers.post["Content-Type"] = "application/json";

const massiveAxiosInstance = axios.create({
  baseURL: config.CAMPAIGNS_API_URL,
});
massiveAxiosInstance.defaults.headers.post["Content-Type"] = "application/json";

// intercepting to capture errors
function successHandler(response) {
  return response.data ? response.data : response;
}
function errorHandler(error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  let message;
  if (!error.response) {
    return Promise.reject(error.message || error);
  }
  switch (error.response.status) {
    case 500:
      message = "Internal Server Error";
      break;
    case 400:
    case 403:
      message = error;
      break;
    case 401:
      message = "Invalid credentials";
      if (!error.config || error.config.url !== '/login') {
        localStorage.removeItem("authUser");
        window.location = "login";
      }
      break;
    case 404:
      message = "Sorry! the data you are looking for could not be found";
      break;
    case 413:
      message = 'PAYLOAD_TOO_LARGE';
      break;
    case 415:
      message = 'UNSUPPORTED_MEDIA_TYPE';
      break;
    default:
      message = error.message || error;
  }
  return Promise.reject(message);
}

axios.interceptors.response.use(successHandler, errorHandler);
msgAxiosInstance.interceptors.response.use(successHandler, errorHandler);
massiveAxiosInstance.interceptors.response.use(successHandler, errorHandler);

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  msgAxiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token;
  massiveAxiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token;
};

class APIClient {
  /**
   * Fetches data from given url
   */
  get = (url, params) => {
    return axios.get(url, params);
  };

  post = (url, data) => {
    return axios.post(url, data);
  };

  /**
   * post given data to url
   */
  create = (url, data) => {
    return axios.post(url, data);
  };

  /**
   * Updates data
   */
  update = (url, data) => {
    return axios.patch(url, data);
  };

  /**
   * Delete
   */
  delete = (url) => {
    return axios.put(url);
  };

  /**
   * Remove
   */
  remove = (url) => {
    return axios.delete(url);
  };

  /**
   * Patch
   */
  patch = (url, data) => {
    return axios.patch(url, data);
  };

  /**
   * Put
   */
  put = (url) => {
    return axios.put(url);
  };

  getFromMessageApi = (url, params) => {
    return msgAxiosInstance.get(url, params);
  };

  postToMessageApi = (url, data, config) => {
    return msgAxiosInstance.post(url, data, config);
  };

  deleteToMessageApi = (url, config) => {
    return msgAxiosInstance.delete(url, config);
  };

  getFromCrmApi = (url, data) => {
    return sperantAxiosInstance.get(url, data);
  };

  postToCrmApi = (url, data) => {
    return sperantAxiosInstance.post(url, data);
  };

  getFromMassiveApi = (url, config) => {
    return massiveAxiosInstance.get(url, config);
  }
}

export { APIClient, setAuthorization };
