import {
  GET_SEGMENTS_SUCCESS,
  GET_SEGMENTS_ERROR,
  GET_SEGMENTS_LOADING,
  CREATE_SEGMENT_SUCCESS,
  CREATE_SEGMENT_ERROR,
  UPDATE_SEGMENT_ERROR,
  UPDATE_SEGMENT_SUCCESS,
  DELETE_SEGMENT_SUCCESS,
  SEND_SEGMENT_SUCCESS,
  SEND_SEGMENT_LOADING,
  CHANGE_SHOW_MODAL_SEGMENT,
} from "./constants";

const initialState = {
  loading: false,
  error: { message: "", status: false },
  data: [],
  loadingRemove: false,
  errorRemove: { message: "", status: false },
  loadingUpdate: false,
  errorUpdate: { message: "", status: false },
  loadingCreate: false,
  errorCreate: { message: "", status: false },
  showModal: false,
};

const Segments = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_SHOW_MODAL_SEGMENT:
      return { ...state, showModal: action.payload };
    case SEND_SEGMENT_SUCCESS:
      return { ...state, data: action.payload };
    case SEND_SEGMENT_LOADING:
      return { ...state, data: action.payload };

    case GET_SEGMENTS_LOADING:
      return { ...state, loading: action.payload };
    case GET_SEGMENTS_ERROR:
      return { ...state, error: action.payload };
    case CREATE_SEGMENT_ERROR:
      return { ...state, errorCreate: action.payload };
    case UPDATE_SEGMENT_ERROR:
      return { ...state, errorUpdate: action.payload };
    case GET_SEGMENTS_SUCCESS:
      return { ...state, data: action.payload };
    case CREATE_SEGMENT_SUCCESS:
      return { ...state, data: action.payload };
    case UPDATE_SEGMENT_SUCCESS:
      return { ...state, data: action.payload };
    case DELETE_SEGMENT_SUCCESS:
      return { ...state, data: action.payload };
    default:
      return state;
  }
};

export default Segments;
