export const GET_TEMPLATES = "GET_TEMPLATES";
export const SET_ACTIVE_SIDEBAR_TAB = "SET_ACTIVE_SIDEBAR_TAB";
export const GET_TEMPLATES_ERROR = "GET_TEMPLATES_ERROR";
export const GET_TEMPLATES_SUCCESS = "GET_TEMPLATES_SUCCESS";
export const SET_TEMPLATES = "SET_TEMPLATES";
export const SET_WA_TEMPLATES = "SET_WA_TEMPLATES";
export const SET_SORTED_TEMPLATES = "SET_SORTED_TEMPLATES";

export const SEND_TEMPLATE = "SEND_TEMPLATE";

export const CHANGE_VIEW_TEMPLATES = "CHANGE_VIEW_TEMPLATES"
export const CHANGE_USER_IN_TEMPLATES = "CHANGE_USER_IN_TEMPLATES";
export const CHANGE_EDIT_WA_TEMPLATE_ID = "CHANGE_EDIT_WA_TEMPLATE_ID";
