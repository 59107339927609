import { UPDATE_MEMBERS, CLEAN_MEMBERS } from "./constants";

const initialState = {
  members: null,
};

const Members = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_MEMBERS:
      return {
        ...state,
        members: action.payload,
      };
    case CLEAN_MEMBERS:
      return {
        ...state,
        members: null,
      };

    default:
      return { ...state };
  }
};

export default Members;
