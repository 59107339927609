import { SET_NOTES } from "./constants";

const initialState = {
  notes: [],
};

const Notes = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTES:
      return { ...state, notes: action.payload };
    default:
      return state;
  }
};

export default Notes;
