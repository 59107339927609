export const GET_TAGS = "GET_TAGS";
export const GET_TAGS_ERROR = "GET_TAGS_ERROR";
export const GET_TAGS_SUCCESS = "GET_TAGS_SUCCESS";
export const GET_TAGS_LOADING = "GET_TAGS_LOADING";

export const CREATE_TAG = "CREATE_TAG";
export const CREATE_TAG_ERROR = "CREATE_TAG_ERROR";
export const CREATE_TAG_SUCCESS = "CREATE_TAG_SUCCESS";
export const CREATE_TAG_LOADING = "CREATE_TAG_LOADING";

export const UPDATE_TAG = "UPDATE_TAG";
export const UPDATE_TAG_ERROR = "UPDATE_TAG_ERROR";
export const UPDATE_TAG_SUCCESS = "UPDATE_TAG_SUCCESS";
export const UPDATE_TAG_LOADING = "UPDATE_TAG_LOADING";

export const DELETE_TAG = "DELETE_TAG";
export const DELETE_TAG_ERROR = "DELETE_TAG_ERROR";
export const DELETE_TAG_SUCCESS = "DELETE_TAG_SUCCESS";
export const DELETE_TAG_LOADING = "DELETE_TAG_LOADING";

export const DUPLICATE_CREATE_TAG_ERROR = "DUPLICATE_CREATE_TAG_ERROR";
