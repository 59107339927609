import React, {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setAuthorization } from "../../helpers/apiClient";
import { getToken } from "../../helpers/authUtils";
import { fetchUser } from "../../redux/actions";
import config from "./../../config";

const withAuthentication = (Component) => {
    const WithAuthentication =  ({...props}) => {
        const dispatch = useDispatch();
        const token = getToken();
        const user = useSelector(state => state.Auth.user?.name);
        setAuthorization(token);

        useEffect(() =>{
            if(!user) {
                dispatch(fetchUser(token));
            }
        },[])
        useEffect(() => {
          if (!user) return;

          const handleStatusUnavailable = () => {
            window.fetch(`${config.API_URL}/users/me/service-status-unavailable`, {
              method: 'PUT',
              headers: { Authorization: `Bearer ${token}` },
              keepalive: true,
            });
            const time = Date.now();
            while (Date.now() - time < 500);
          }
    
          window.addEventListener('unload', handleStatusUnavailable);
          return () => {
            window.removeEventListener('unload', handleStatusUnavailable);
          }
        }, [user]);
        return  <Component {...props} />;
        }

        return WithAuthentication;
}

export default withAuthentication;