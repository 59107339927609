import { APIClient } from "../../helpers/apiClient";

import { SET_NOTES } from "./constants";

const apiClient = new APIClient();
const { get, remove } = apiClient;

export const setNotes = (notes) => {
  return {
    type: SET_NOTES,
    payload: notes,
  };
};

export const getNotes = (contactId) => async (dispatch, getState) => {
  try {
    const response = await get(`/notes?contactId=${contactId}`);
    const id_after = getState().Contact.activeContact._id;
    if (contactId === id_after) {
      if (Array.isArray(response) && response.length > 0) {
        dispatch(setNotes(response));
      } else dispatch(setNotes([]));
    }
  } catch (e) {
    console.log(e);
  }
};

//action no usado
export const deleteNotes = (noteId) => async (dispatch) => {
  try {
    await remove(`/notes/${noteId}`);
    dispatch(getNotes());
  } catch (e) {
    console.log(e);
  }
};
