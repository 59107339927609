import { SET_COUNTER_PENDING } from "./constants";

const initialState = {
  counterPending: 0,
};

const Counter = (state = initialState, action) => {
  switch (action.type) {
    case SET_COUNTER_PENDING:
      return { ...state, counterPending: action.payload };
    default:
      return state;
  }
};

export default Counter;
