import React from "react";
import { Redirect } from "react-router-dom";

// lazy load all the views
const Dashboard = React.lazy(() => import("../pages/dashboard/index"));
// const StarterPage = React.lazy(() => import("../pages/StarterPage/index"));

// auth
const Login = React.lazy(() => import("../pages/Auth/Login.jsx"));
const Logout = React.lazy(() => import("../pages/Auth/Logout.jsx"));
const ForgetPassword = React.lazy(() => import("../pages/Auth/ForgetPassword.jsx"));
const Register = React.lazy(() => import("../pages/Auth/Register.jsx"));
const LockScreen = React.lazy(() => import("../pages/Auth/LockScreen.jsx"));

// declare all routes
const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard,},
];
// { path: "/pages-starter", component: StarterPage },
// {
//   path: "/",
//   exact: true,
//   component: () => <Redirect to="/dashboard" />,
// },

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forget-password", component: ForgetPassword },
  { path: "/register", component: Register },
  { path: "/lock-screen", component: LockScreen},
];

const routes = [...authProtectedRoutes, ...publicRoutes];

export { authProtectedRoutes, publicRoutes, routes };
