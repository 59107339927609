import React from "react";
import Loading from "../../components/general/Loading";
import LeftSidebarMenu from "./LeftSidebarMenu.jsx";
import { useSelector } from "react-redux";
import withAuthentication from "./withAuthentication";

const Index = (props) => {
  const loading = useSelector((state) => state.Auth.loading);

  return (
    <React.Fragment>
      {loading ? (
        <Loading />
      ) : (
        <div className="layout-wrapper d-lg-flex">
          <LeftSidebarMenu />
          {props.children}
        </div>
      )}
    </React.Fragment>
  );
};

export default withAuthentication(Index);
