import {
  SET_ACTIVE_CONTACT,
  UPDATE_IFRAME,
  TYPE_UPDATE_IFRAME,
  SET_TAG_LVL,
} from "./constants";

const initialState = {
  activeContact: null,
  updateIframe: [],
  typeUpdateIframe: "",
  tagLvl: []
};

const Contact = (state = initialState, action) => {
  switch (action.type) {
    case SET_TAG_LVL:
      return {
        ...state,
        tagLvl: action.payload,
      };

    case UPDATE_IFRAME:
      return {
        ...state,
        updateIframe: action.payload,
      };

    case TYPE_UPDATE_IFRAME:
      return {
        ...state,
        typeUpdateIframe: action.payload,
      };

    case SET_ACTIVE_CONTACT: {
      return {
        ...state,
        activeContact: action.payload,
      };
    }

    default:
      return { ...state };
  }
};

export default Contact;
