import { APIClient } from "../../helpers/apiClient";

import {
  GET_MEMBERS,
  UPDATE_MEMBERS,
  CLEAN_MEMBERS,
} from "./constants";

const apiClient = new APIClient();
const { get } = apiClient;

export const getMembers = (groupId) => async (dispatch, getState) => {
  try {
    const members = await get(`/users?groupId=${groupId}`);
    const currentGroupId = getState().Group.currentGroup?.id;
    if (currentGroupId && groupId) dispatch(updateMembers(members));
  } catch (e) {
    console.log(e);
  }
};

export const updateMembers = (members) => ({
  type: UPDATE_MEMBERS,
  payload: members,
});

export const cleanMembers = () => ({
  type: CLEAN_MEMBERS,
});