import { APIClient } from "../../helpers/apiClient";

import { SET_COUNTER_PENDING } from "./constants";

const apiClient = new APIClient();
const { get } = apiClient;

export const setCounterPending = (counter) => {
  return {
    type: SET_COUNTER_PENDING,
    payload: counter,
  };
};

export const getCounter =
  (groupId, filter, tagIdList, filterTagId, filterTagsLvl, typeFilter) =>
  async (dispatch, getState) => {
    try {
      let params = {};
      params.filter = filter;

      if(['group', 'contact'].includes(typeFilter))
        params.lastsender = typeFilter;
      else if (['archived', 'important', 'finished'].includes(typeFilter))
        params[typeFilter] = true;

      params.tagIdList = tagIdList;
      params.filterTagId = filterTagId;
      params.filterTagsLvl = filterTagsLvl;
      const response = await apiClient.getFromMessageApi(`/contacts/lastchats/counter?groupId=${groupId}`, {
        params,
        headers: { GroupId: groupId }
      });
      const _typeFilter = getState().Chat.typeFilter;
      const id_after = getState().Group.currentGroup._id;
      if (id_after === groupId) {
        if (response && typeFilter === _typeFilter) {
          dispatch(setCounterPending(response.pendingChatsAmount));
          // dispatch(setCounterAll(response.noPendingchatsAmount + response.pendingChatsAmount));
        }
        else {
          dispatch(setCounterPending(0));
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
