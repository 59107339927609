export const GET_SEGMENTS = "GET_SEGMENTS";
export const GET_SEGMENTS_ERROR = "GET_SEGMENTS_ERROR";
export const GET_SEGMENTS_LOADING = "GET_SEGMENTS_LOADING";
export const GET_SEGMENTS_SUCCESS = "GET_SEGMENTS_SUCCESS";

export const CREATE_SEGMENT = "CREATE_SEGMENT";
export const CREATE_SEGMENT_LOADING = "CREATE_SEGMENT_LOADING";
export const CREATE_SEGMENT_ERROR = "CREATE_SEGMENT_ERROR";
export const CREATE_SEGMENT_SUCCESS = "CREATE_SEGMENT_SUCCESS";

export const UPDATE_SEGMENT = "UPDATE_SEGMENT";
export const UPDATE_SEGMENT_LOADING = "UPDATE_SEGMENT_LOADING";
export const UPDATE_SEGMENT_ERROR = "UPDATE_SEGMENT_ERROR";
export const UPDATE_SEGMENT_SUCCESS = "UPDATE_SEGMENT_SUCCESS";

export const DELETE_SEGMENT = "DELETE_SEGMENT";
export const DELETE_SEGMENT_LOADING = "DELETE_SEGMENT_LOADING";
export const DELETE_SEGMENT_ERROR = "DELETE_SEGMENT_ERROR";
export const DELETE_SEGMENT_SUCCESS = "DELETE_SEGMENT_SUCCESS";

export const SEND_SEGMENT = "SEND_SEGMENT";
export const SEND_SEGMENT_LOADING = "SEND_SEGMENT_LOADING";
export const SEND_SEGMENT_ERROR = "SEND_SEGMENT_ERROR";
export const SEND_SEGMENT_SUCCESS = "SEND_SEGMENT_SUCCESS";
export const SEND_SEGMENT_SUCCESS_CLEAN = "SEND_SEGMENT_SUCCESS_CLEAN";

export const CHANGE_SHOW_MODAL_SEGMENT = "CHANGE_SHOW_MODAL_SEGMENT";
