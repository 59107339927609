import { APIClient } from "../../helpers/apiClient";

import {
  GET_SEGMENTS,
  GET_SEGMENTS_LOADING,
  GET_SEGMENTS_ERROR,
  GET_SEGMENTS_SUCCESS,
  CREATE_SEGMENT,
  CREATE_SEGMENT_LOADING,
  CREATE_SEGMENT_ERROR,
  CREATE_SEGMENT_SUCCESS,
  UPDATE_SEGMENT,
  UPDATE_SEGMENT_LOADING,
  UPDATE_SEGMENT_ERROR,
  UPDATE_SEGMENT_SUCCESS,
  DELETE_SEGMENT,
  DELETE_SEGMENT_LOADING,
  DELETE_SEGMENT_ERROR,
  DELETE_SEGMENT_SUCCESS,
  SEND_SEGMENT,
  SEND_SEGMENT_SUCCESS,
  SEND_SEGMENT_ERROR,
  SEND_SEGMENT_LOADING,
  CHANGE_SHOW_MODAL_SEGMENT
} from "./constants";

const apiClient = new APIClient();
const { get, create, patch, remove } = apiClient;

export const changeShowModalSegment = (value) => {
  return {
    type: CHANGE_SHOW_MODAL_SEGMENT,
    payload: value,
  };
};

export const sendSegment = (segment) => async (dispatch, getState) => {
  const currentGroup = getState().Group.currentGroup;
  const dataSegment = getState().Segments.data;

  let filterSegment;
  dataSegment.forEach((itemSegment, keyIndex) => {
    if (itemSegment.id === segment.segmentId) {
      filterSegment = { segment: itemSegment, index: keyIndex };
    }
  });
  let updateSegment = filterSegment.segment;
  let copyDataSegment = dataSegment.map((item) => item);

  updateSegment.sending = true;
  copyDataSegment[filterSegment.index] = updateSegment;

  dispatch(sendSegmentLoading(copyDataSegment));

  try {
    if (currentGroup) {
      const { id: groupId, apiKey } = currentGroup;

      let bodySegment = segment;
      bodySegment.groupId = groupId;
      bodySegment.apiKey = apiKey;

      const response = await create(
        "/contacts/send-template",
        bodySegment
      );

      updateSegment.sending = false;
      updateSegment.sendSuccess = response;
      copyDataSegment[filterSegment.index] = updateSegment;

      dispatch(sendSegmentSuccess(copyDataSegment));
    }
  } catch (error) {
    console.log(error);

    updateSegment.sending = false;
    copyDataSegment[filterSegment.index] = updateSegment;

    dispatch(sendSegmentLoading(copyDataSegment));
  }
};

export const sendSegmentLoading = (status) => {
  return {
    type: SEND_SEGMENT_LOADING,
    payload: status,
  };
};

export const sendSegmentSuccess = (payload) => {
  return {
    type: SEND_SEGMENT_SUCCESS,
    payload,
  };
};

export const getSegments = () => async (dispatch, getState) => {
  try {
    const currentGroup = getState().Group.currentGroup;
    if (currentGroup) {
      const { id: groupId } = currentGroup;
      const response = await get("/v2/segments", { params: { groupId } });
      dispatch(getSegmentsSuccess(response));
    }
  } catch (error) {
    console.log(error);
  }
};

export const getSegmentsSuccess = (tags) => {
  return {
    type: GET_SEGMENTS_SUCCESS,
    payload: tags,
  };
};

export const getSegmentsLoading = (loadingStatus) => {
  return {
    type: GET_SEGMENTS_LOADING,
    payload: loadingStatus,
  };
};

export const getSegmentsError = (message, status) => {
  return {
    type: GET_SEGMENTS_ERROR,
    payload: { message, status },
  };
};

export const createSegment = (segment) => async (dispatch, getState) => {
  try {
    const currentGroup = getState().Group.currentGroup;
    const dataSegment = getState().Segments.data;

    if (currentGroup) {
      const { id: groupId } = currentGroup;

      let bodySegment = segment;
      bodySegment.groupId = groupId;
      const response = await create("/v2/segments", bodySegment);

      let copyDataSegment = [...dataSegment];
      copyDataSegment.push(response);
      dispatch(createSegmentSuccess(copyDataSegment));
      dispatch(changeShowModalSegment(false));
      dispatch(createSegmentError("", false));
    }
  } catch (error) {
    console.log(error);
    dispatch(createSegmentError(error, true));
  }
};

export const createSegmentSuccess = (segment) => {
  return {
    type: GET_SEGMENTS_SUCCESS,
    payload: segment,
  };
};

export const createSegmentLoading = (loadingStatus) => {
  return {
    type: GET_SEGMENTS_LOADING,
    payload: loadingStatus,
  };
};

export const createSegmentError = (message, status) => {
  return {
    type: CREATE_SEGMENT_ERROR,
    payload: { message, status },
  };
};

export const updateSegment = (idSegment, segment) => async (dispatch, getState) => {
  try {
    const dataSegment = getState().Segments.data;

    const response = await patch(
      `/v2/segments/${idSegment}`,
      segment
    );

    const findIndex = dataSegment.findIndex(
      (segment) => segment.id === idSegment
    );

    let copyDataSegment = [...dataSegment];
    copyDataSegment[findIndex] = response;

    dispatch(updateSegmentSuccess(copyDataSegment));
    dispatch(changeShowModalSegment(false));
    dispatch(updateSegmentError("", false));
  } catch (error) {
    console.log(error);
    dispatch(updateSegmentError(error, true));
  }
};

export const updateSegmentSuccess = (payload) => {
  return {
    type: UPDATE_SEGMENT_SUCCESS,
    payload,
  };
};

export const updateSegmentError = (message, status) => {
  return {
    type: UPDATE_SEGMENT_ERROR,
    payload: { message, status },
  };
};

export const deleteSegment = (idSegment) => async (dispatch, getState) => {
  try {
    const dataSegment = getState().Segments.data;

    const response = await remove(`/v2/segments/${idSegment}`);

    const findIndex = dataSegment.findIndex(
      (segment) => segment.id === idSegment
    );

    let copyDataSegment = [...dataSegment];
    copyDataSegment.splice(findIndex, 1);

    dispatch(deleteSegmentSuccess(copyDataSegment));
  } catch (error) {
    console.log(error);
  }
};

export const deleteSegmentSuccess = (newArraySegments) => {
  return {
    type: DELETE_SEGMENT_SUCCESS,
    payload: newArraySegments,
  };
};
