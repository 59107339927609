/**
 *
 * @param {string} groupId
 * @param {object} user
 * @returns {boolean} if the user is admin, returns true. Otherwise, returns false.
 */
export const isSuperAdmin = (groupId, user) => {
  const currentGroupPermission = user?.permissions?.find((permission) => permission.groupId === groupId && permission.role === "ROLE_ADMI");
  return !!currentGroupPermission?.isSuperAdmin;
};

export const isAdmin = (groupId, user) => {
  const permission = user?.permissions && user.permissions.find((_permission) => {
    return _permission.groupId === groupId;
  });
  if (permission?.role === "ROLE_ADMI") {
    return true;
  } else {
    return false;
  }
};

export const isPublisher = (groupId, user) => {
  const permission = user?.permissions && user.permissions.find((_permission) => {
    return _permission.groupId === groupId;
  });
  if (permission?.role === "ROLE_PUBLISHER") {
    return true;
  } else {
    return false;
  }
};

/**
 *
 * @param {object} user
 * @returns {boolean} if the user is publisher in all groups, returns true. Otherwise, returns false.
 */
export const isPublisherAllGroups = (user) => {
  var publisherAllGroups = true;
  for (let i = 0; i < user.permissions.length; i++) {
    const _permission = user.permissions[i];
    if (_permission.role != "ROLE_PUBLISHER") {
      publisherAllGroups = false;
      break;
    }
  }
  return publisherAllGroups;
};
