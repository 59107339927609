import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    FETCH_USER,
    FETCH_USER_SUCCESS,
    API_FAILED,
    SET_GROUPS,
  SET_PERMISSIONS_CURRENT_GROUP,
  SET_ENTER_KEY_SWITCH_CHECKED,
  SET_SERVICE_STATUS,
} from './constants';

import { getLoggedInUser } from '../../helpers/authUtils';

const INIT_STATE = {
    user: null,
    loading: true,
  permissionsCurrentGroup: null,
  serviceStatus: 1,
  enterKeySwitchChecked: false,
};


const Auth = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return { ...state, loading: true };
        case SET_SERVICE_STATUS:
            return { ...state, serviceStatus: action.payload };
        case SET_PERMISSIONS_CURRENT_GROUP:
          return { ...state, permissionsCurrentGroup: action.payload };
        case LOGIN_USER_SUCCESS:
            return { ...state, token: action.payload , loading: false, error: null };
        case FETCH_USER:
            return { ...state, loading: true };
        case FETCH_USER_SUCCESS:
            return { ...state, user: action.payload.user, loading: false, error: null };
        case REGISTER_USER:
            return { ...state, loading: true };
        case REGISTER_USER_SUCCESS:
            return { ...state, user: action.payload, loading: false, error: null };
        case LOGOUT_USER:
            return { ...state, user: null };
        case FORGET_PASSWORD:
            return { ...state, loading: true };
        case FORGET_PASSWORD_SUCCESS:
            return { ...state, passwordResetStatus: action.payload, loading: false, error: null };
        case API_FAILED:
            return { ...state, loading: false, error: action.payload };
        case SET_GROUPS:
            return { ...state, groups: action.payload }
        case SET_ENTER_KEY_SWITCH_CHECKED:
            return { ...state, enterKeySwitchChecked: action.payload }
        default: return { ...state };
    }
}

export default Auth;