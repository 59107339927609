import { APIClient } from "../../helpers/apiClient";
import {
  SET_CAMPAIGNS,
} from "./constants";

const apiClient = new APIClient();

export const setCampaigns = (campaigns) => ({
  type: SET_CAMPAIGNS,
  payload: campaigns,
});

export const getCampaigns = () => async (dispatch, getState) => {
  try {
    const currentGroupId = getState().Group.currentGroup?.id;

    dispatch(setCampaigns([]));
    const response = await apiClient.getFromMassiveApi("/api/operations", {
      headers: { groupId: currentGroupId }
    });

    const currentGroupIdAfterReq = getState().Group.currentGroup?.id;
    if (currentGroupId === currentGroupIdAfterReq && Array.isArray(response) && response.length > 0) {
      dispatch(setCampaigns(response));
    }
  } catch (e) {
    console.log(e)
  }
};

export const getCampaign = (operationId) => async (dispatch, getState) => {
  try {
    const currentGroupId = getState().Group.currentGroup?.id;
    if (operationId) {
      const response = await apiClient.getFromMassiveApi(`/api/operations/${operationId}?short=true`, {
        headers: { groupId: currentGroupId }
      });

      const currentGroupIdAfterReq = getState().Group.currentGroup?.id;
      const campaigns = getState().Campaigns.campaigns;

      if (currentGroupId === currentGroupIdAfterReq && response?.operationId) {
        dispatch(setCampaigns([...campaigns, {
          id: response.operationId,
          templateId: response.templateId,
          name: response.name,
        }]));
      }
    }
  } catch (e) {
    console.log(e);
  }
}
