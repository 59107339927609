import {
  GET_TAGS_SUCCESS,
  GET_TAGS_ERROR,
  GET_TAGS_LOADING,
  CREATE_TAG_SUCCESS,
  CREATE_TAG_ERROR,
  CREATE_TAG_LOADING,
  DUPLICATE_CREATE_TAG_ERROR
} from "./constants";

const initialState = {
  loading: false,
  error: { message: "", status: false },
  data: [],
  loadingRemove: false,
  errorRemove: { message: "", status: false },
  loadingUpdate: false,
  errorUpdate: { message: "", status: false },
  loadingCreate: false,
  errorCreate: { message: "", status: false },
  errorDuplicate: { message: "", status: false, data: null},
};

const Tags = (state = initialState, action) => {
  switch (action.type) {
    case GET_TAGS_LOADING:
      return { ...state, loading: action.payload };
    case GET_TAGS_ERROR:
      return { ...state, error: action.payload };
    case GET_TAGS_SUCCESS:
      return { ...state, data: action.payload };

    case CREATE_TAG_SUCCESS:
      return { ...state, data: action.payload };
    case CREATE_TAG_LOADING:
      return { ...state, loadingCreate: action.payload };
    case CREATE_TAG_ERROR:
      return { ...state, errorCreate: action.payload };
    case DUPLICATE_CREATE_TAG_ERROR:
      return { ...state, errorDuplicate: action.payload };

    default:
      return state;
  }
};

export default Tags;
