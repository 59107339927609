import React from "react";

const Avatar = ({ nameLetter, size = "xs" }) => {
  const sizeClass = `avatar-${size}`;
  const fontSize = size === "lg" ? "font-size-24" : size === "sm" ? "font-size-20" : "";
  return (
    <div className={sizeClass}>
      <span
        className={`avatar-title rounded-circle bg-soft-primary text-primary ${fontSize}`}
      >
        {nameLetter}
      </span>
    </div>
  );
};

export default Avatar;
