import { APIClient } from "../../helpers/apiClient";

import {
  CREATE_TEMPLATES_MESSAGE,
  GET_TEMPLATES_MESSAGE,
  UPDATE_TEMPLATES_MESSAGE,
  SET_TEMPLATES_MESSAGE,
  ADD_TEMPLATES_MESSAGE,
  RESET_STATUS_QUERY_TEMPLATES_MESSAGE,
  UPDATE_STATUS_QUERY_TEMPLATES_MESSAGE,
  UPDATE_ONE_TEMPLATES_MESSAGE,
  SET_TEMPLATES_MESSAGE_SORTED,
  SET_TEMPLATES_INTERACTIVE_SORTED,
} from "./constants";

const apiClient = new APIClient();
const { get, create, patch, remove } = apiClient;

export const updateTemplatesMessage = (data) => async (dispatch, getState) => {
  try {
    const { name, message, file, mediaTemplate, typeTemplate, quickReplyType, quickReplyOptions, quickReplyFilename, listTitle, listOptions } = data;
    let bodyPatch = {
      name: data.name,
      content: {
        text: data.message,
        type: typeTemplate,
      },
    };
    if (typeTemplate == 'quick_reply') {
      bodyPatch.content.contentMedia = mediaTemplate;
      bodyPatch.content.contentType = quickReplyType;
      bodyPatch.content.options = quickReplyOptions;
      if (quickReplyType == 'file')
        bodyPatch.content.filename = quickReplyFilename;
    } else if (typeTemplate == 'list') {
      bodyPatch.content.title = listTitle;
      bodyPatch.content.options = listOptions;
    } else {
      if (name && message && file) {
        const formData = new FormData();
        formData.append("media", file);
        formData.append("type", typeTemplate);
        const media = await create("/files", formData, {
          headers: "multipart/form-data",
        });
        bodyPatch.content.media = media.url;
      } else if (mediaTemplate) {
        bodyPatch.content.media = mediaTemplate;
      }
    }
    const id_before = getState().Group.currentGroup.id;
    const bodyResponse = await patch(
      `/mtemplates/${data.id}`,
      bodyPatch
    );

    const id_after = getState().Group.currentGroup.id;
    if (id_before === id_after) dispatch(updateOneTemplatesMessage(bodyResponse));
  } catch (e) {
    console.log(e);
  }
};

export const updateOneTemplatesMessage = (data) => {
  return {
    type: UPDATE_ONE_TEMPLATES_MESSAGE,
    payload: data,
  };
};

export const getTemplatesMessage = () => async (dispatch, getState) => {
  try {
    const currentGroup = getState().Group.currentGroup;

    if (currentGroup) {
      const { id: groupId } = currentGroup;

      const response = await get(`/mtemplates?groupId=${groupId}`);
      const id_after = getState().Group.currentGroup.id;
      if (groupId === id_after) dispatch(setTemplatesMessage(response));
    }
  } catch (error) {
    console.log("error", error.message);
  }
};

export const setTemplatesMessage = (allMessages) => {
  return {
    type: SET_TEMPLATES_MESSAGE,
    payload: allMessages,
  };
};

export const createTemplatesMessage = (data) => async (dispatch, getState) => {
  try {
    const { currentGroup } = getState().Group;
    const { id: groupId } = currentGroup;
    const { name, message, file, typeTemplate, quickReplyType, quickReplyOptions, listTitle, listOptions } = data;

    let bodyRequest = {
      groupId,
      name,
      content: {
        text: message,
        type: typeTemplate,
      },
    };
    
    if (name && message && file) {
      const formData = new FormData();
      formData.append("media", file);
      formData.append("type", typeTemplate);
      const media = await create("/files", formData, {
        headers: "multipart/form-data",
      });
      if (typeTemplate == 'quick_reply') bodyRequest.content.contentMedia = media.url;
      else bodyRequest.content.media = media.url;
    }
    if (typeTemplate == 'quick_reply') {
      bodyRequest.content.contentType = quickReplyType;
      bodyRequest.content.options = quickReplyOptions;
      if (quickReplyType == 'file') bodyRequest.content.filename = file.name;
    } else if (typeTemplate == 'list') {
      bodyRequest.content.title = listTitle;
      bodyRequest.content.options = listOptions;
    }
    const id_before = getState().Group.currentGroup.id;
    const bodyResponse = await create("/mtemplates", bodyRequest);

    const id_after = getState().Group.currentGroup.id;
    if (id_before === id_after) dispatch(addTemplatesMessage(bodyResponse));
  } catch (e) {
    console.log(e.message);
  }
};

export const addTemplatesMessage = (message) => {
  return {
    type: ADD_TEMPLATES_MESSAGE,
    payload: message,
  };
};

export const removeTemplate = (id) => async (dispatch, getState) => {
  try {
    const templates = getState().TemplatesMessage.templatesMessage;
    const id_before = getState().Group.currentGroup.id;
    const response = await remove(`/mtemplates/${id}`);
    const id_after = getState().Group.currentGroup.id;
    if (id_before === id_after) {
      const findIndex = templates.findIndex(item => item.id === id);

      let newArrayTemplates = [...templates];
      newArrayTemplates.splice(findIndex, 1);

      dispatch(setTemplatesMessage(newArrayTemplates));
    }
  } catch (error) {
    console.log(error);
  }
};

export const resetStatusQueryTemplatesMessage = () => {
  return {
    type: RESET_STATUS_QUERY_TEMPLATES_MESSAGE,
  };
};

export const updateStatusQueryTemplatesMessage = (payload) => {
  return {
    type: UPDATE_STATUS_QUERY_TEMPLATES_MESSAGE,
    payload,
  };
};

export const getTemplatesMessageSorted = () => async (dispatch, getState) => {
  try {
    const currentGroup = getState().Group.currentGroup;

    if (currentGroup) {
      const { id: groupId } = currentGroup;

      const response = await get(`/mtemplates/bypositions?groupId=${groupId}`);
      const id_after = getState().Group.currentGroup.id;
      if (groupId === id_after) dispatch(setTemplatesMessageSorted(response));
    }
  } catch (error) {
    console.log("error", error.message);
  }
};

export const setTemplatesMessageSorted = (allMessages) => {
  return {
    type: SET_TEMPLATES_MESSAGE_SORTED,
    payload: allMessages,
  };
};

export const getTemplatesInteractiveSorted = () => async (dispatch, getState) => {
  try {
    const currentGroup = getState().Group.currentGroup;

    if (currentGroup) {
      const { id: groupId } = currentGroup;

      const response = await get(`/mtemplates/interactive/bypositions?groupId=${groupId}`);
      const id_after = getState().Group.currentGroup.id;
      if (groupId === id_after) dispatch(setTemplatesInteractiveSorted(response));
    }
  } catch (error) {
    console.log("error", error.message);
  }
};

export const setTemplatesInteractiveSorted = (allMessages) => {
  return {
    type: SET_TEMPLATES_INTERACTIVE_SORTED,
    payload: allMessages,
  };
};
