import {
  LOCK_LOADING_GENERAL_APP,
  SET_EVENT_NAME_SHOW_BLUR,
  SET_ROWS_AREA,
  SET_ERROR_CODES,
} from "./constants";

export const lockLoadingGeneralApp = (status) => {
  return {
    type: LOCK_LOADING_GENERAL_APP,
    payload: status,
  };
};

export const setEventNameShowBlur = (status) => {
  return {
    type: SET_EVENT_NAME_SHOW_BLUR,
    payload: status,
  };
};

export const setNroRowsArea = (rows) => {
  return {
    type: SET_ROWS_AREA,
    payload: rows,
  };
};

export const setErrorCodes = (errors) => {
  return {
    type: SET_ERROR_CODES,
    payload: errors,
  };
};
