import { createStore, applyMiddleware, compose } from 'redux';
// import createSagaMiddleware from "redux-saga";
import thunk from 'redux-thunk';
import reducers from './reducers';
// import sagas from "./sagas";

// const sagaMiddleware = createSagaMiddleware();
const middlewares = [thunk];

export function configureStore(initialState) {
    const composeEnhancers = (process.env.NODE_ENV !== "production" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ )|| compose;

    const store = createStore(
        reducers,
        initialState,
        composeEnhancers(
            applyMiddleware(...middlewares)
        ),
    );
    // sagaMiddleware.run(sagas);
    return store;
}
